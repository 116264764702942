.discount-heading {
  display: flex;
  justify-content: space-between;
}

.discount-coupon-label {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  margin: 0 !important;
}
