.layout-container {
  min-height: 100vh;
}

.content-container {
  padding: 10px;
}

.header {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  border-width: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  min-height: 45px;
  background: #438eb9;
  display: flex;
  justify-content: space-between;
  .brand-logo {
    color: #fff;
    font-size: 24px;
    text-shadow: none;
    padding-left: 20px;
    height: auto;
  }
  .header-dropdown {
    padding-right: 20px;
    cursor: pointer;
    .profile-avatar {
      border: 2px solid #fff;
    }
    .welcome-text {
      color: #fff;
      padding: 5px;
      small {
        padding-right: 5px;
      }
    }
  }
}

.footer {
  text-align: center;
  a {
    color: rgb(255, 64, 129);
  }
}

.ant-layout-sider {
  background: #f0f0f0;
}

.ant-layout-sider-trigger {
  background: #438eb9;
}
