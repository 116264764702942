.manage-attendees {
  display: flex;
  text-align: center;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  margin-right: 20px;
}

.manage-attendees i {
  background-color: #333;
  border-radius: 50%;
  font-size: 25px;
  color: white;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.manage-attendees i:hover {
  color: rgb(194, 24, 91);
  transform: scale(1.2);
  transition-property: all;
  transition-duration: 150ms;
}

.manage-attendees p {
  padding-top: 5px;
}

.manage-workshop-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.manage-workshop-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: max-content;
  width: max-content;
  cursor: pointer;
}

.waiver-signed {
  color: rgb(19, 196, 19);
  cursor: pointer;
}

.waiver-not-signed {
  color: rgb(234, 39, 18);
  cursor: pointer;
}

/* Modal Content */
.modal-content {
  padding: 10px;
}

/* Modal Message */
.modal-message {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
}

/* Modal Actions */
.modal-actions {
  display: flex;
  justify-content: flex-end;
}

/* Button Styles */
.modal-action-btn {
  margin-left: 10px;
}

/* Cancel Button */
.cancel-btn {
  background-color: #ccc;
  color: #333;
  border-color: #ccc;
}

.cancel-btn:hover {
  background-color: #bbb;
  border-color: #bbb;
}

/* Confirm Button */
.confirm-btn {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.confirm-btn:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.verify-previous-certification {
  display: flex;
  align-items: center;
  gap: 5px;
}

.refund-request-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.attendee-name {
  color: rgb(194, 24, 91);
  text-transform: capitalize;
}
