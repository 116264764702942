.ant-descriptions-item-content {
  margin-bottom: 10px;
}
.ant-descriptions-row {
  padding-right: 10px !important;
}
.info-main-container {
  background-color: #f3f4f7;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.info-container {
  margin-bottom: 20px;
  border-radius: 5px;

  border: none !important;
  padding: 0 24px 24px !important;
}

.info-container > div {
  border: none !important;
  padding: 0 !important;
  position: relative;
}

.info-heading {
  position: absolute;
  top: -40px;
  right: 0;
  color: rgb(0, 115, 255);
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  gap: 0.5rem;
}

.info-item {
  padding: 0 !important;
  position: relative;
}
.main_title {
  display: flex;
  height: 207px;
}
.info {
  padding-right: 10px !important;
}
.info-user {
  display: flex !important;
  align-items: center !important;
  width: max-content !important;
  gap: 5px;
}

.menu {
  background-color: #f3f4f7;
  font-size: 11px !important;
}

.activeStatus {
  padding: 1px 0px;
  text-align: center;
  background-color: #98b433;
  border-radius: 2px;
  margin-left: 10px;
  font-size: 10px;
  color: #fff;
  letter-spacing: 1px;
  margin: 0 10px;
  width: 57px;
}
.trialingStatus {
  padding: 1px 0px;
  text-align: center;
  background-color: #98b433;
  border-radius: 2px;
  margin-left: 10px;
  font-size: 10px;
  color: #fff;
  letter-spacing: 1px;
  margin: 0 10px;
  padding: 03px 10px;
  min-width: 57px;
}
.inactiveStatus {
  padding: 1px 2px;
  text-align: center;
  background-color: #a9a9a7;
  border-radius: 2px;
  margin-left: 10px;
  font-size: 10px;
  color: #fff;
  letter-spacing: 1px;
  margin: 0 10px;
  width: 60px;
}
.openStatus {
  padding: 1px 2px;
  background-color: #f5c13c;
  border-radius: 2px;
  margin-left: 15px;
  font-size: 10px;
  color: #fff;
  letter-spacing: 1px;
}
.deletion {
  background-color: #f87171;
}

.userBadge {
  display: flex;
  align-items: center;
  padding: 0px 0px;
}
.userBadge-image {
  margin-right: 10px;
}
.userBadge-detail {
  display: flex;
  flex-direction: column;
}

.userBadge-detail span {
  font-size: 13px !important;
}
.userBadge-detail > span:nth-child(2) {
  color: #a3a1a1 !important;
}
.link {
  color: rgb(0, 115, 255) !important;
  cursor: pointer;
  margin-inline: 3px;
}
.medal {
  width: 20px !important;
  margin-left: 5px;
}

.des_item {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.des_item .des_label {
  font-size: 10px;
  color: #a3a1a1;
}
.des_item .des_value {
  font-size: 14px;
}

.profilePicModal {
  width: fit-content !important;

  .ant-modal-body {
    padding: 0 !important ;
    width: fit-content !important;
  }
  .anticon svg {
    display: inline-block;
    color: white;
  }
}

.icon-list {
  cursor: default;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.userSearchField {
  .ant-input-affix-wrapper {
    line-height: 1.5878 !important;
  }
}

.overlay-content {
  position: relative;
  top: 46%;
  width: 60%;
  text-align: center;
  margin-top: 30px;
  margin: auto;
}
.overlay {
  height: 100%;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.05);
}

.pbt-pl {
  margin-left: 2px;
}

.delete-btn {
  background-color: red;
  border-radius: 0.5rem;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.delete-btn:hover {
  background-color: rgb(221, 0, 0);
}

.myprofile-actions {
  display: flex;
  gap: 10px;
}
