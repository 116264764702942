.deal-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  .manage-deal-container {
    width: 100%;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
  }

  .deal-table {
    width: 100%;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
  }
}
