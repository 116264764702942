.income-table {
  .ant-table-cell {
    padding: 11px !important;
  }
}

.income-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.workshopIncome {
  .ant-drawer-wrapper-body {
    width: 100%;
    padding-bottom: 10px;

    .ant-empty-normal {
      margin-top: 10px !important;
      margin-bottom: 10px !important;
    }

    .income-container {
      padding: 15px 1.6rem;
      display: flex;
      flex-direction: column;
      gap: 15px;

      .workshop-income-views-container {
        display: flex;
        gap: 8px;
        padding: 1rem;
        border-radius: 8px;
        background-color: #ffffff; /* bg-white */
        border: 2px solid #f2f2f2;
        font-weight: 600 !important;
        color: #1890ff !important;
        font-size: 1.2rem !important;

        .workshop-income-views-container-title {
          margin: 0;
        }
        .workshop-income-views-container-value {
          margin: 0;
        }
      }

      .income-table-container {
        background-color: #ffffff;
        border-radius: 8px;
        overflow: hidden;
        border: 2px solid #f2f2f2;

        .table-header {
          padding: 1rem;
          background: #f2f2f2 !important;

          h2 {
            margin: 0;
            font-weight: 600;
            color: #1890ff !important;
          }
        }

        .income-table {
          padding: 1rem;
        }
      }

      .expense-income-container {
        background-color: #ffffff;
        border: 2px solid #f2f2f2;
        border-radius: 8px;
        overflow: hidden;
        padding: 1rem;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .expense {
          border-right: 2px solid #f2f2f2;
          padding-right: 1rem;

          .expense-heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            p {
              margin: 0;
              font-size: 1.2rem;
              font-weight: 600;
              color: #1890ff !important;
            }
          }
        }

        .income {
          padding-left: 1rem;
        }
      }
    }

    .income-table-section {
      .income-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        p {
          margin: 0;
          font-weight: 600;
          color: #1890ff !important;
          font-size: 1.2rem;
        }

        .action-buttons {
          // font-size: 1.2rem;
          // padding: 0 !important;
        }
      }
    }

    /* //custom scroll bar */
    .ant-drawer-body::-webkit-scrollbar {
      height: 4px;
      width: 6px;
    }

    .ant-drawer-body::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 6rem;
    }

    .ant-drawer-body::-webkit-scrollbar-thumb {
      background: #c8c8c8 !important;
      border-radius: 6rem;
      cursor: pointer;
    }

    .ant-drawer-body::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
