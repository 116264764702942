@import '~antd/dist/antd.css';

body {
  margin: 0;
}

.no-margin {
  margin: 0;
}

.w-full {
  width: 100%;
}

.scrollableDiv {
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
}

/* //custom scroll bar */
.custom-scrollbar::-webkit-scrollbar {
  height: 4px;
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6rem;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgb(24, 144, 255) !important;
  border-radius: 6rem;
  cursor: pointer;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.w-full {
  width: 100%;
}

.w-200px {
  width: 200px;
}
