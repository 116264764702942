.workshop {
  background: white;
}

.workshopLink {
  text-transform: capitalize;
}

.workshopLink:hover {
  color: #02ccfe;
}

.hideWorkshop {
  cursor: pointer;
}

.workshopTitle {
  display: flex;
  gap: 10px;
  align-items: center;
}

.workshopTitle > .workshopLink {
  margin: 0 !important;
}

.workshop-dropdown {
  font-size: 24px;
}

.workshop-dropdown-overlay {
  .ant-dropdown-menu {
    border-radius: 10px;
    padding: 5px;
  }
  .ant-dropdown-menu-item {
    border-radius: 10px;
  }
}
