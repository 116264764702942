.login,
.forgot {
  background: white;
}
.heading {
  background: #438eb9;
  color: white !important;
  padding: 20px;
  text-align: center;
}
.login_form_forgot {
  color: #438eb9;
  font-size: 18px;
  font-weight: 600;
  padding: 0px 23px 55px 0px;
}
.login_form_forgot > span {
  float: right;
}
