/* .ant-tabs-tab-active {
  background-color: #363636 !important;
}
.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
} */
.version {
  display: flex;
  width: 300px;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin-bottom: 10px;
}

.version button {
  width: 100px;
}

.exercise-video-custom-thumb {
  .ant-upload {
    width: 100%;
  }
}
