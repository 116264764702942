.modal-title {
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  margin: 0;
}

.modal-desc {
  font-size: 1rem;
  font-weight: 400;
}

.modal-btn-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 1rem;
}
