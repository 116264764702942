.assessment-video-uploader {
  .ant-upload-select {
    width: 100% !important;
    height: 100% !important;
    display: block !important;
  }
  .ant-upload.ant-upload-select {
    width: 100% !important;
    height: 100% !important;
    display: block !important;
  }

  .ant-upload-list-item-container {
    width: 100% !important;
    height: 100% !important;
  }
}

.ant-upload-list-item-list-type-picture-card,
.ant-upload-list-picture-card-container {
  width: 100% !important;
  height: 100px !important;
}

.tencent-upload-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 228px;
  width: 100% !important;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 5px;
  .div {
    margin-top: 10px;
    font-size: 16px;
  }
  cursor: pointer;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.tencent-upload-button:hover {
  border: 1px dashed #1890ff;
}
