.email-container {
  display: grid;
  grid-template-columns: 1.1fr 2fr;
  margin-top: 16px;
}

.email-list {
  background-color: #ffffff;
  border-radius: 8px 0px 0px 8px;
  border-right: 1px solid #f4f4f4;
  min-width: 450px;
}

#email-list-scroll {
  height: 700px;
  overflow-y: scroll;
  scrollbar-width: 2px;
  padding: 16px 0 0 16px;
}

.email-content {
  background-color: #ffffff;
  border-radius: 0 8px 8px 0px;
  min-width: 500px;
}

.email-body {
  padding: 16px;
  overflow-y: auto;
}

.email-item {
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 8px;
  margin-right: 10px;
  padding: 12px;
}

.email-item:hover {
  background-color: #f5f5f5;
}

.email-item.selected {
  background-color: #f2f2f2;
}

.ant-divider {
  background-color: #444;
}

.ant-pagination {
  margin-top: 16px;
}

/* //custom scroll bar */
.email-scrollbar::-webkit-scrollbar {
  height: 4px;
  width: 6px;
}

.email-scrollbar::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 6rem;
}

.email-scrollbar::-webkit-scrollbar-thumb {
  background: #f2f2f2 !important;
  border-radius: 6rem;
  cursor: pointer;
}

.email-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.email-header {
  border-bottom: 1px solid #f4f4f4;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.email-avatar {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100%;
}

.email-scrollbar {
  min-height: 400px;
  max-height: 400px;
  overflow-y: auto;
}

.email-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.no-email {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.email-details {
  border-bottom: 1px solid #f4f4f4;
  padding: 16px;
}

.no-margin {
  margin: 0 !important;
}
