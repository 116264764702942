.launch_workshop {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}
.ant-form-item {
  margin-right: 10px;
}

.uploadbutton {
  background-color: #594495;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  border-color: #594495;
}

.uploadbutton:hover {
  background-color: #f4f4f4;
  color: black;
  border-color: #ddd;
}

.buttonSelected,
.buttonSelected:hover,
.buttonSelected:focus {
  background-color: #f4f4f4;
  color: black;
  border-color: #ddd;
}

.large-image {
  max-width: 600px;
  max-height: 400px;
}

.large {
  position: absolute;
  left: -9999px;
}

.preview:hover .large {
  left: 100px;
  top: -150px;
}

.split-structure {
  tr > th,
  tr > td {
    padding: 8px !important;
  }
}

.workshop-instructor-file-upload {
  .ant-upload-select-picture-card {
    width: 100%;
    margin: 0 !important;
  }
}
