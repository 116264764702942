.certificationcard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fafafa;
  border: 1px solid #f2f2f2;
  padding: 10px 20px;
  margin-bottom: 10px;
  gap: 20px;
}

.certificationcard-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.certification-action {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.action-buttons-edit {
  color: rgb(0, 115, 255);
  cursor: pointer;
}

.action-buttons-danger {
  color: rgb(255, 17, 0);
  cursor: pointer;
}
