.forgot_form {
  padding: 20px;
}
.forgot_btn {
  background: #438eb9;
  border: #438eb9;
  &:hover {
    background: #438eb9;
  }
}
